<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "bàba",
          hanzi: "爸爸",
          english: "dad",
          image: require(`@/assets/img/12-Vocabulary/lesson-09-baba-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "māma",
          hanzi: "妈妈",
          english: "mom",
          image: require(`@/assets/img/12-Vocabulary/lesson-09-mama-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "ài",
          hanzi: "爱",
          english: "to love",
          image: require(`@/assets/img/12-Vocabulary/lesson-09-ai-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "jiā",
          hanzi: "家",
          english: "I; me",
          image: require(`@/assets/img/12-Vocabulary/lesson-09-jia-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "Wǒ ài……",
          hanzi: "我爱......",
          english: "I love…",
          hanziClass: "font-hanzi-medium",
          pinyinClass: "font-pinyin-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-09-woai-bubble.svg`),
          blue: true,
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
